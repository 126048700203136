
<template>
  <div style="width: 100%;height: 100%; display: flex;justify-content: center;align-items: center;flex-direction: column;">
    <div :id="id" :ref="id"></div>
    <el-button type="primary" class="margin-top20" @click="downloadQrcode">下载</el-button>
    <a ref="locatorQRCodeDownloadLinkRef" style="display: none"></a>
  </div>

</template>
<script>
import QRCode from 'qrcodejs2'
export default {
  data() {
    return {
      qrcode: ''
    }
  },
  props: {
    id: {
      type: String,
      required: true
    },
    codeUrl: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '200'
    },
    height: {
      type: String,
      default: '200'
    },
    colorDark: {
      type: String,
      default: '#000000'
    },
    colorLight: {
      type: String,
      default: '#ffffff'
    }
  },
  watch: {
    codeUrl() {
      this.createQrcode()
    }
  },
  mounted() {
    this.createQrcode()
  },
  methods: {
    // 有新的二维码地址了，先把之前的清除掉
    createQrcode() {
      if (this.qrcode) {
        this.$refs[this.id].innerHTML = ''
      }
      this.qrcode = new QRCode(this.$refs[this.id],
          {
            text: this.codeUrl,
            width: this.width,
            height: this.height,
            colorDark: this.colorDark,
            colorLight: this.colorLight,
            correctLevel: QRCode.CorrectLevel.H,
          })
    },
    downloadQrcode() {//下载
      let downloadLink = this.$refs.locatorQRCodeDownloadLinkRef;
      let qrcodeCanvas = ((this.$refs[this.id] || {})?.getElementsByTagName?.('canvas') || [])?.[0];
      let qrcodeImgUrl = qrcodeCanvas?.toDataURL?.('image/png'); // 作为下载图片资源
      downloadLink.setAttribute('href', qrcodeImgUrl);
      downloadLink.setAttribute(
          'download',
          `二维码_${new Date().getTime()}.png`
      );
      downloadLink.click();
      URL.revokeObjectURL(downloadLink.href);
    },
  }
}
</script>
